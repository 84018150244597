<template>
	<li
		class="main-stock"
		:class="{ unavailable }"
	>
		<TnParagraph
			:size="grid ? 'xs' : 's'"
			:responsive="true"
		>
			<template v-if="text">
				<template v-if="loading">
					<TnIcon
						name="spinner"
						class="margin-right-s spinner icon"
					/>Sjekker nettlager-status...
				</template>
				<template v-else>
					<TnIcon
						v-if="!unavailable"
						class="margin-right-s icon"
						:name="autoStockAttributes.icon"
						:color="autoStockAttributes.color"
					/>
					<TnIcon
						name="close"
						class="margin-right-s icon"
						v-else
					></TnIcon
					>{{ autoStockAttributes.text }}
				</template>
			</template>
			<template v-else>
				<template v-if="loading">
					<TnIcon
						name="spinner"
						class="margin-right-s spinner icon"
					/>Sjekker nettlager-status...
				</template>
				<template v-else-if="stock > 0 && !unOrderable">
					<TnIcon
						class="margin-right-s icon"
						:name="autoStockAttributes.icon"
						:color="autoStockAttributes.color"
					/>{{ autoStockAttributes.text }}
				</template>
				<template v-else-if="unOrderable || stockReplacementText || cardStockReplacementText">
					<TnIcon
						class="margin-right-s icon"
						name="info-circle-filled"
						color="#005fce"
					/>{{ stockReplacementText || cardStockReplacementText }}
				</template>
				<template v-else>
					<TnIcon
						:name="stock <= 0 && autoStockAttributes.custom ? autoStockAttributes.icon : 'success-circle-filled'"
						class="margin-right-s icon"
					/>{{
						stock <= 0 && autoStockAttributes.custom
							? autoStockAttributes.text
							: (unVerifiedDeliveryText ?? `Kommer på lager`)
					}}
				</template>
			</template>
		</TnParagraph>
	</li>
</template>

<script>
import ECommerceProductMixin from "~/components/ecommerce/ECommerceProductMixin/ECommerceProductMixin.js";
import { mapState } from "vuex";

export default defineNuxtComponent({
	name: "MainStock",
	mixins: [ECommerceProductMixin],

	props: {
		mainStock: {
			type: String,
		},
		unVerifiedDeliveryText: {
			type: String,
		},
		text: {
			type: String,
		},
		mainStockIcon: {
			type: String,
		},
		mainStockIconColor: {
			type: String,
		},
		thresholds: {
			type: Array,
		},
		grid: {
			type: Boolean,
			default: false,
		},
		product: {
			type: Object,
			default: () => ({}),
		},
	},

	mounted() {
		this.$emit("isUnavailable", this.unavailable);
	},

	computed: {
		...mapState("ecommerce", ["categoriesThatDontRequireStock"]),
		localProduct() {
			if (!this.grid) return this.eCommerceProduct;
			return this.product;
		},
		unavailable() {
			if (this.grid && (this.stockReplacementText || this.cardStockReplacementText || !this.outOfStock)) return false;
			else if (this.outOfStock) return true;
			if (this.stock < this.localProduct?.stockThreshold) return true;
			if (this.unOrderable) return true;
			if (this.mainStock === "Not in stock") return true;
			if (this.mainStock === "In stock") return false;
			if (this.loading) return false;
			return false;
		},
		outOfStock() {
			if (!this.categoriesThatDontRequireStock.includes(this.localProduct.category) && this.stock === 0) {
				return true;
			}
			return false;
		},
		loading() {
			return this.localProduct?.loading;
		},
		stock() {
			return this.localProduct?.inventory?.[0]?.amount || 0;
		},
		unOrderable() {
			return this.localProduct?.unOrderable && !!this.stockReplacementText;
		},
		stockReplacementText() {
			if (this.grid) return undefined;
			return this.localProduct?.stockReplacementText;
		},
		cardStockReplacementText() {
			if (this.grid) {
				return this.localProduct?.cardStockReplacementText;
			}
			return undefined;
		},
		autoStockAttributes() {
			const value = this.localProduct?.inventory?.[0]?.amount || 0;
			const attributes = {
				color: "#fd4d63",
				icon: "remove-circle-filled",
				text: "Ikke tilgjengelig",
				custom: false,
			};
			if (value >= 20) {
				attributes.color = "#38a841";
				attributes.icon = "success-circle-filled";
				attributes.text = "Mer enn 20 igjen på lager";
			}
			if (value >= 5) {
				attributes.color = "#38a841";
				attributes.icon = "success-circle-filled";
				attributes.text = "På lager";
			}
			if (value > 0 && value < 5) {
				attributes.color = "#ff962c";
				(attributes.icon = "minus-circle-filled"), (attributes.text = "Få på nettlager");
			}
			if (value === 0 && this.outOfStock) {
				attributes.text = "Ikke på nettlager";
				attributes.icon = "info-circle-filled";
				attributes.custom = true;
			}
			for (const item of this.thresholds || []) {
				if ((item?.moreThan || 0) <= value && value < (item?.lessThan || 9999)) {
					(attributes.color = item?.mainStockIconColor || attributes.color),
						(attributes.icon = item?.mainStockIcon || attributes.icon),
						(attributes.text = item?.mainStockText || attributes.text),
						(attributes.custom = true);
				}
			}
			return attributes;
		},
	},
});
</script>

<style lang="scss" scoped>
@keyframes rotation {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(359deg);
	}
}

.spinner {
	animation: rotation 2s infinite linear;
}

.unavailable {
	color: $color-neutrals-600-shade;
}

.main-stock {
	display: flex;
	align-items: center;
	list-style-type: none;
}

.icon {
	position: relative;
	top: 5px;
}
</style>
